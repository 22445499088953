<template>
  <div>
    <el-input disabled size="mini" placeholder="请选择链接" :value="value">
      <template slot="append">
        <div @click="dialogVisible=true" class="y-pointer">选择链接</div>
      </template>
    </el-input>
    <el-dialog custom-class="custom" append-to-body title="选择链接" :show-close="false" :visible.sync="dialogVisible" width="50rem">
      <el-tabs v-model="activeName" style="height: 20rem">
        <el-tab-pane label="系统链接" name="sys">
          <template v-for="(item,key) in sys">
            <el-button @click="chooseSys(key)" :key="key">{{item.title}}</el-button>
          </template>
        </el-tab-pane>
        <el-tab-pane v-if="active_id > 0" label="商家主页" name="merchant">
          <template v-for="(item,key) in merchant_list">
            <el-button @click="chooseMerchantHome(key)" :key="key">{{item.title}}</el-button>
          </template>
        </el-tab-pane>
        <el-tab-pane v-if="$store.state.addons.shop" label="商城模式" name="merchant">
          <template v-for="(item,key) in shop">
            <el-button @click="chooseShop(key)" :key="key">{{item.title}}</el-button>
          </template>
        </el-tab-pane>
        <el-tab-pane label="自定义链接" name="custom">
          <div class="flex-def flex-zCenter flex-cCenter" style="height: 10rem">
            <el-input style="width: 30rem" placeholder="请输入" v-model="custom">
              <template slot="append">
                <el-button @click="sureCustom" style="padding:0 3rem;" size="mini">确认</el-button>
              </template>
            </el-input>
          </div>
        </el-tab-pane>
      </el-tabs>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "y_choose_link",
  model:{
    prop:"modelval",
    event:'change'
  },
  props:{
    modelval: {
      type:String,
      default:""
    },
    active_id:{
      type:Number,
      default: 0
    }
  },
  watch:{
    modelval(){
      this.value = this.modelval;
    },
    value(e){
      this.$emit('change',e);
    }
  },
  data(){
    return{
      value: this.modelval,
      custom:"",
      dialogVisible:false,
      activeName:"sys",
      sys:[
        {title:"聚合首页",link:"/pages/index/index"},
        {title:"商户入驻",link:"/pages/merchant/setin"},
        {title:"个人中心",link:"/pages/mine/index"},
      ],
      shop:[
        {title:"商品列表页",link:"/pages/plugin/shop/goods-list"},
        {title:"商家列表页",link:"/pages/plugin/shop/merchant-list"},
        // {title:"商品首页",link:"/pages/plugin/shop/home"},
        {title:"商家管理页",link:"/pages/plugin/shop/manage/index"},
      ],
      merchant_list:[],
    }
  },
  mounted() {
    this.loadActiveMerchantList();
  },
  methods:{
    loadActiveMerchantList(){
      if(!this.active_id)return
      if(this.$route.path.includes("shop/design")){
        this.$api.plugin.shop.designMerchantList({
          id : this.active_id
        }).then(res=>{
          this.merchant_list = res;
        })
      }else {
        this.$api.active.merchantList({
          active_id:this.active_id
        }).then(res=>{
          this.merchant_list = res;
        })
      }
    },
    chooseMerchantHome(key){
      let item = this.merchant_list[key];
      this.value = `/pages/merchant/home?id=${item.id}`
      this.dialogVisible = false;
    },
    sureCustom(){
      this.dialogVisible=false;
      this.value = this.custom;
    },
    chooseSys(key){
      let item = this.sys[key];
      this.value = item.link;
      this.dialogVisible = false;
    },
    chooseShop(key){
      let item = this.shop[key];
      this.value = item.link;
      this.dialogVisible = false;
    }
  }
}
</script>
<style>
.custom>.el-dialog__body{
  padding: 0 20px 30px !important;
  margin: 0;
}
</style>
<style scoped>

</style>