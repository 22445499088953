import { render, staticRenderFns } from "./banner.vue?vue&type=template&id=9554dfce&scoped=true&"
import script from "./banner.vue?vue&type=script&lang=js&"
export * from "./banner.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9554dfce",
  null
  
)

export default component.exports